import axios from 'axios'

export function getAllTipoDoc () {
  return axios.get('/maestros/tipodoc')
  .then(response => {
    return response.data
  })
}

export function updateTipoDoc (id, tipo) {
  return axios.put('/maestros/tipodoc/' + id, tipo)
  .then(response => {
      return response.data
    })
}

export function addTipoDoc (tipo) {
  return axios.post('/maestros/tipodoc', tipo)
  .then(response => {
    return response.data
  })
}

export function deleteTipoDoc (id) {
  return axios.delete('/maestros/tipodoc/' + id)
  .then(response => {
      return response.data
    })
}

export function getOneTipoDoc (id) {
    return axios.get('/maestros/tipodoc/' + id)
    .then(response => {
        return response.data
      })
}

export function getLogsTipoDoc (id) {
  return axios.get('/maestros/tipodoc/audit/' + id)
  .then(response => {
      return response.data
    })
}

export default {
  getAllTipoDoc,
  updateTipoDoc,
  addTipoDoc,
  deleteTipoDoc,
  getOneTipoDoc,
  getLogsTipoDoc,
}
